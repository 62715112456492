import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "./slice/loginSlice"
import featureMasterReducer from "./slice/featureMasterSlice"
import userReducer from "./slice/userSlice"
import contactReducer from "./slice/contactSlice"


export const store = configureStore({
    reducer: {
        login: loginReducer,
        featureMaster: featureMasterReducer,
        user: userReducer,
        contact: contactReducer
    },
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch