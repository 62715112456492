import React, { lazy } from 'react'
import AuthLayout from '../Layout/AuthLayout';
import MainLayout from '../Layout/MainLayout';
import OpenLayout from '../Layout/OpenLayout';
import { CONTACT, DASHBOARD, LOGIN, OTP, SALES_ANALYSIS, USER } from './routes';
import { Navigate } from 'react-router-dom';
import SalesAnalysis from '../pages/sales-analysis';

const RouteBuilder = (isLoggedIn: boolean) => {

  // Private pages
  const DashBoard = lazy(() => import("../pages/dashboard"))
  const User = lazy(() => import("../pages/userList"))
  const Contact = lazy(() => import("../pages/contact"))

  const NotFound = lazy(() => import("../pages/not-found"))

  // open pages
  const Login = lazy(() => import("../pages/login"))
  const Otp = lazy(() => import("../pages/otp"))
 
  return [
    {
      element: <MainLayout />,
      children:
        [
          { path: DASHBOARD, element: <DashBoard /> },
          { path: USER, element: <User /> },
          { path: CONTACT, element: <Contact /> },
          { path: SALES_ANALYSIS, element: <SalesAnalysis /> }
        ],
    },
    {
      element: <AuthLayout />,
      children:
        [
          { path: LOGIN, element: <Login /> },
          { path: OTP, element: <Otp /> },
        ],
    },
    {
      element: <OpenLayout />,
      children:
        [
          { path: "*", element: isLoggedIn ? <NotFound /> : <Navigate to={LOGIN} /> },
        ],
    },
  ]
}

export default RouteBuilder