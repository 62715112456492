
import React, { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import CustomLoader from '../component/common/CustomLoader'
import { useUserContext } from '../context/UserContext'
import { LOGIN } from '../routes/routes'
import SideNav from './SideNav'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Toolbar } from '@mui/material'

const MainLayout = () => {

  const { isLoggedIn } = useUserContext()

  if (isLoggedIn)
    return (
      <>
        {/* TODO : add header/footer and sidebar and wrap below element as content body */}
        < Suspense fallback={< CustomLoader />}>
          <div className="d-flex w-100 p-0 m-0 vh-100">
            <div className='h-100 bg-primary'>
              <SideNav />
            </div>
            <div className="d-flex flex-column flex-grow-1 bg-light overflow-hidden vh-100">
              <div className="d-flex flex-shrink-0 justify-content-between align-items-center px-4 shadow-sm bg-white" style={{height: '75px'}}>
                <h5 className="text-primary fw-bold">Overall Sales Performance</h5>

                <div className="bg-light" style={{height: '50px', width: '50px', borderRadius: '50%'}}></div>
              </div>

              <div className="d-flex flex-column flex-grow-1 overflow-y-scroll">
              <Outlet />
              </div>
            </div>
          </div >
        </Suspense >
      </>
    )

  return (
    <Navigate to={LOGIN} />
  )




}

export default MainLayout