import { Link } from "react-router-dom"
import { useUserContext } from "../context/UserContext"
import { useState } from "react";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { CONTACT, DASHBOARD, USER } from "../routes/routes";
import APP_LOGO from "../assets/zeiss-logo.svg";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 280
const navItems = [
  { "label": "Sales Performance", "path": "/" },
  { "label": "Sales Analysis", "path": "/" },
  { "label": "Sales Comparison", "path": "/" },
  { "label": "Regional Sales Distribution", "path": "/" },
  { "label": "Financial Metric", "path": "/" },
  { "label": "MSP Performance", "path": "/" },
  { "label": "Market Penetration", "path": "/" },
  { "label": "Operational Efficiency", "path": "/" },
  { "label": "Team Performance", "path": "/" },
  { "label": "Product Performance", "path": "/" },
  { "label": "Customer Stats", "path": "/" },
  { "label": "Help Desk", "path": "/" },
  { "label": "Forecast Trends", "path": "/" },
  { "label": "Supply Chain", "path": "/" },
  { "label": "Market Trends", "path": "/" }
]


const SideNav = (props: Props) => {

  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const { logout } = useUserContext()

  

  return (
    <>
      <div className="d-flex flex-column bg-primary px-4" style={{minHeight: '100vh', width: drawerWidth}}>
        <div className="logo-container d-flex justify-content-center">
          <div className="logo bg-light mt-4 p-3" style={{height: '100px', width: '100px', borderRadius: '50%'}}>
            <img src={APP_LOGO} alt="..." style={{height: '100%', width: '100%', objectFit: 'cover'}} />
          </div>
        </div>

        <div className="sidebar-container d-flex flex-column py-4">
          {
            navItems.map((navLink: any, key: number) => (
              <div className="my-1" key={key}>
                <span className="text-white">
                  <strong>{navLink.label}</strong>
                </span>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default SideNav