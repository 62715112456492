import React from 'react';

const SalesAnalysis = () => {
  return (
    <></>
    // <>
    //   <div className="p-4">
    //     <div className="bg-white p-4">
    //       <div className="row">
    //         {
    //           dashboardCard.map((data, key) => (
    //             <div className="col-3" key={key}>
    //               <div className="bg-light rounded p-4">
    //                 <p className="text-primary fw-bold p-0 m-0">{data.label}</p>
    //                 <h1 className="text-primary fw-bold p-0 m-0">{data.value}</h1>
    //               </div>
    //             </div>
    //           ))
    //         }
    //       </div>
    //     </div>
    //   </div>

    //   <div className="row g-0 py-0 px-4 m-0">
    //     <div className="col-12">
    //     <div className="bg-white p-4 mb-4">
    //       <h6 className="fw-bold text-black text-uppercase mb-3">Todays Order</h6>
    //       <ResponsiveContainer width="100%" aspect={5}>
    //         <LineChart
    //           width={500}
    //           height={300}
    //           data={todayOrderData}
    //           margin={{
    //             top: 5,
    //             right: 30,
    //             left: 20,
    //             bottom: 5,
    //           }}
    //         >
    //           <CartesianGrid strokeDasharray="3 3" />
    //           <XAxis dataKey="time_interval" />
    //           <YAxis />
    //           <Tooltip />
    //           <Line type="monotone" dataKey="order_count" stroke="#8884d8" />
    //           {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    //         </LineChart>
    //       </ResponsiveContainer>
    //     </div>
    //     </div>

    //     <div className="col-6">
    //     <div className="bg-white p-4 mb-4 me-2 h-100">
    //       <h6 className="fw-bold text-black text-uppercase mb-3">Sales by brand</h6>
    //       <ResponsiveContainer width="100%" aspect={3}>
    //         <PieChart width={730} height={250}>
    //           {/* <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" /> */}
    //           <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
    //         </PieChart>
    //       </ResponsiveContainer>
    //     </div>
    //     </div>

    //     <div className="col-6">
    //     <div className="bg-white p-4 mb-4 ms-2 h-100">
    //       <ResponsiveContainer width="100%" aspect={2}>
    //           <BarChart width={730} height={250} data={singleBarChartData}>
    //             <CartesianGrid strokeDasharray="3 3" />
    //             <XAxis dataKey="name" />
    //             <YAxis />
    //             <Tooltip />
    //             <Legend />
    //             <Bar dataKey="pv" fill="#8884d8" />
    //             <Bar dataKey="uv" fill="#82ca9d" />
    //           </BarChart>
    //         </ResponsiveContainer>
    //     </div>
    //     </div>

    //     <div className="col-12">
    //     <div className="bg-white p-4 mb-4">
    //       <h6 className="fw-bold text-black text-uppercase mb-3">Sales by region</h6>
    //         <ResponsiveContainer width="100%" aspect={4}>
    //           <BarChart width={730} height={250} data={saleByRegionData}>
    //             <CartesianGrid strokeDasharray="3 3" />
    //             <XAxis dataKey="name" />
    //             <YAxis />
    //             <Tooltip />
    //             <Legend />
    //             <Bar dataKey="pv" fill="#8884d8" />
    //             <Bar dataKey="uv" fill="#82ca9d" />
    //           </BarChart>
    //         </ResponsiveContainer>
    //     </div>
    //     </div>

    //     <div className="col-12">
    //     <div className="bg-white p-4 mb-4">
    //       <h6 className="fw-bold text-black text-uppercase mb-3">Sales Trend</h6>
    //       <ResponsiveContainer width="100%" aspect={5}>
    //       <LineChart
    //         width={500}
    //         height={300}
    //         data={todayOrderData}
    //         margin={{
    //           top: 5,
    //           right: 30,
    //           left: 20,
    //           bottom: 5,
    //         }}
    //       >
    //         <CartesianGrid strokeDasharray="3 3" />
    //         <XAxis dataKey="time_interval" />
    //           <YAxis />
    //           <Tooltip />
    //           <Line type="monotone" dataKey="order_count" stroke="#8884d8" />
    //         {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
    //       </LineChart>
    //       </ResponsiveContainer>
    //     </div>
    //     </div>
    //   </div>
    // </>
  )
}

export default SalesAnalysis;